import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import DefaultPicture, {
  propTypes as defaultPicturePropTypes,
} from '../../../01_atoms/DefaultPicture';
import TagText from '../../../01_atoms/Tag/TagText';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBFeaturedEventImage = ({
  imagePicture,
  label = '',
  eventName,
  description = '',
  button,
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb bb-featured-event-image ${
      styles['bb-featured-event-image']
    } ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    title={imagePicture.alt ? imagePicture.alt : null}
    id={uuid}
  >
    <div className="container">
      <Link {...button.nextLink}>
        <a className="view-box-wrapper" href={button.nextLink.url}>
          <div className="view-box">
            <div className="image-box">
              <DefaultPicture className="responsive-image" {...imagePicture} loading="lazy" />
            </div>

            {label && <TagText label={label} isUppercase />}

            <div className="content">
              <h2 className="title header-two">{eventName}</h2>

              {description && <h3 className="description header-two">{description}</h3>}
            </div>
            <div className="action">
              <Button
                tag="button"
                type="primary"
                className="bb-featured-event--button"
                withArrow
                size="extra-large"
              >
                {button.label}
              </Button>
            </div>
          </div>
        </a>
      </Link>
    </div>
  </div>
);

BBFeaturedEventImage.propTypes = {
  imagePicture: PropTypes.shape(defaultPicturePropTypes).isRequired,
  label: PropTypes.string,
  eventName: PropTypes.string.isRequired,
  description: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBFeaturedEventImage;
